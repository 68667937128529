<template>
  <App-necker />
  <div class="container max-w-[600px] py-20">
    <h1 class="font-bold text-2xl lg:text-4xl">{{ row.title }}</h1>
    <div class="wysiwyg mt-8" v-html="row.content"></div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import PostService from '@/services/Post';
import { useRoute } from 'vue-router';
export default {
  setup() {
    const route = useRoute();
    const row = reactive({
      title: '',
      content: '',
    });
    PostService.single({ slug: route.params.slug }).then((res) => {
      if (res.code !== 1) {
        return;
      }
      row.title = res.data.title;
      row.content = res.data.content;
    });

    return { row };
  },
};
</script>
